<template>
  <div class="html-block" v-html="html"></div>
</template>
<style>
div.html-block h3 {
  margin-bottom: 1em
}
div.html-block li > p {
  margin-bottom: 0
}
</style>
<script>
import { computed } from '@vue/composition-api'
export default {
  props: {
    info: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const html = computed(() => {
      return props.info.replace('<a ', '<a target="_blank" ')
    })

    return {
      html
    }
  }
}
</script>
